/* istanbul ignore file */
// Home helper functions and data

export const homeSliderImageConfig = {
  width: 324,
  height: 324
};

export const homeSliderImageConfigMobile = {
  width: 168,
  height: 168
};
