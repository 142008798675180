import styled from "styled-components";

import { HomeCardsStyledProps as Props } from "./HomeCards.types";
import CONSTANTS from "config/constants";
const { BREAKPOINTS } = CONSTANTS;

const { tablet, mobile } = BREAKPOINTS;

const HomeCardsStyled = styled.div<Props>`
  display: flex;
  margin-top: 8rem;
  margin-bottom: 6.4rem;
  gap: 0 1.6rem;

  @media (max-width: ${tablet}px) {
    flex-direction: column;
    margin-top: 2.4rem;
    margin-bottom: 4rem;
  }

  .HomeCards {
    &__card {
      min-height: 32rem;
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding-bottom: 0;
      border-radius: 2rem;
      overflow: hidden;
      background-color: var(--palette-gray-s0-l16);
      background-image: url("/assets/images/home/static-banner-01.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @media (max-width: ${tablet}px) {
        flex-direction: column;
      }

      :last-child {
        background-color: var(--palette-gray-s0-l16);
        background-image: url("/assets/images/home/static-banner-02.png");
        margin-left: 2.4rem;

        @media (max-width: ${tablet}px) {
          margin-left: 0;
          margin-top: 1.6rem;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
    }

    &__text {
      position: absolute;
      top: 0;
      left: 2rem;
      padding: 3.2rem 1.2rem 2rem 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: var(--palette-black-s3-l11);
    }

    &__title {
      max-width: 33.6rem;
      display: flex;
      border-radius: 3.2rem;
      font-size: 3.6rem;
      font-weight: 700;
      color: var(--palette-white);
      letter-spacing: -0.2rem;
      line-height: 3.6rem;
      text-transform: uppercase;

      &--primary {
        color: var(--palette-yellow-h38-s92-l59-a10);
      }

      &--secondary {
        color: var(--palette-pink-h28-s68-l72-a10);
      }

      @media (max-width: ${tablet}px) {
        font-size: 4rem;
      }

      @media (max-width: ${mobile}px) {
        max-width: 20.6rem;
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }

    &__download-app-button {
      opacity: 0.6;
      padding: 1.2rem 2rem;
      margin-bottom: 1.6rem;
      font-size: 1.6rem;
      font-weight: 800;
      cursor: not-allowed;
      width: 20rem;
      align-self: flex-end;
      background-color: var(--palette-primary);
      color: var(--palette-white);

      &--secondary {
        opacity: 1;
        cursor: pointer;
        background-color: var(--palette-white);
        color: var(--palette-primary);

        &:hover {
          background-color: var(--palette-white) !important;
        }
      }
    }

    &__button {
      width: 15.2rem;
      font-size: 1.4rem;
      font-weight: 600;
      padding: 1.2rem;
      margin-top: 2rem;
      cursor: pointer;
      opacity: 1;
      background-color: var(--palette-white) !important;
      color: var(--palette-primary) !important;
      border-radius: 0.8rem !important;

      &--inactive {
        cursor: not-allowed;
        opacity: 0.6;
      }

      &--download {
        padding: 1.2rem 0.8rem;
      }
    }
  }
`;

export default HomeCardsStyled;
