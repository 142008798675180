import styled from "styled-components";

import { SliderCardStyledProps as Props } from "./SliderCard.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const SliderCardStyled = styled.div<Props>`
  position: relative;
  cursor: pointer;

  .SliderCard {
    &__image {
      border-radius: 1.4rem;

      .Image__img {
        object-fit: cover;
      }
    }

    &__content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;

      &-badge {
        max-width: 50%;
        padding: 0.4rem 0;
        text-align: center;
        color: var(--palette-text-white);
        border-radius: 2.4rem;
        background-color: var(--palette-gray-s0-l20-a70);
      }

      &-bottom {
        margin-top: auto;
      }

      &-title {
        max-width: 16.8rem;
        line-height: 2rem;
        font-weight: 700;
        font-size: 1.6rem;
        color: var(--palette-gray-s0-l35);
        text-align: center;

        @media (max-width: ${tablet}px) {
          font-size: 1.6rem !important;
          line-height: 1.4rem !important;
        }
      }

      &-button {
        display: flex;
        align-items: center;
        padding: 0;
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--palette-white);
        background-color: transparent;
        border: 0;
        text-shadow: 0 0 0.2rem var(--palette-black);

        &:hover:not([disabled]) {
          color: var(--palette-gray-s0-l92);
        }
      }

      &-svg {
        margin-left: 2rem;
      }
    }
  }
`;

export default SliderCardStyled;
