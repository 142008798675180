import { Slider } from "artisn-ui-react";
import React from "react";

import CategorySliderPlaceholder from "./CategorySlider.placeholder";
import Styles from "./CategorySlider.styles";
import { CategorySliderProps as Props } from "./CategorySlider.types";
import CONSTANTS from "config/constants";
import useWindowSize from "hooks/useWindowSize";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const CategorySlider: React.FC<Props> = props => {
  const { children, title, height, className, isLoading, ...rest } = props;
  const { width } = useWindowSize();
  const isMobile = width <= tablet;

  return (
    <Styles className={`CategorySlider ${className}`}>
      {!isLoading ? (
        <>
          <h2 className="CategorySlider__title">{title}</h2>
          <Slider
            slideWidth={isMobile ? 168 : 324}
            height={height}
            gap={32}
            infinite
            {...rest}
          >
            {children}
          </Slider>
        </>
      ) : (
        <CategorySliderPlaceholder />
      )}
    </Styles>
  );
};

CategorySlider.defaultProps = {
  className: ""
};

export default CategorySlider;
