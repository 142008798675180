import { useRouter } from "next/router";
import React from "react";

import Styles from "./HomeCards.styles";
import { HomeCardsProps as Props } from "./HomeCards.types";
import Button from "components/global/Button/Button";
// import useAuth from "contexts/auth/auth.context.hooks";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";

const { APP_URL } = CONSTANTS.INTEGRATIONS;
const HomeCards: React.FC<Props> = () => {
  const t = useI18n();
  // const { isAnonymous } = useAuth();
  const { push } = useRouter();

  // /**
  //  * Function to handle the click on the coupons button.
  //  *
  //  * @since 1.0.0
  //  */
  // const onClickCoupons = () => {
  //   if (isAnonymous) {
  //     push("/signin");
  //     return;
  //   }

  //   push("/profile/coupons");
  // };
  const onClickDownload = () => push(APP_URL);

  return (
    <Styles className="HomeCards">
      <div className="HomeCards__card">
        <div className="HomeCards__content">
          <div className="HomeCards__text">
            <h3 className="HomeCards__title">{t.home.card.download}</h3>
            <h3 className="HomeCards__title">{t.home.card.ourApp}</h3>
            <Button
              className="HomeCards__button HomeCards__button--download"
              color="white"
              onClick={onClickDownload}
            >
              {t.home.card.downloadNow}
            </Button>
          </div>
        </div>
      </div>
      <div className="HomeCards__card">
        <div className="HomeCards__content">
          <div className="HomeCards__text">
            <h3 className="HomeCards__title">{t.home.card.knowMenu}</h3>
            <Button
              className="HomeCards__button"
              color="white"
              onClick={() => push("/categories")}
            >
              {t.home.menu}
            </Button>
          </div>
        </div>
      </div>
    </Styles>
  );
};

HomeCards.defaultProps = {};

export default HomeCards;
